<template>
  <div>
    <!-- header start-->
    <section class="w-full h-full">
      <div class="bg-[#ffff] py-4 lg:py-4 px-5 border-b-2 border-gray-400">
        <div class="w-full max-w-7xl mx-auto">
          <div class="hidden md:flex justify-between items-center">
            <img
              src="@/assets/program/sharda-Online-Logo.png"
              alt="Sharda Online"
              srcset=""
              class=""
            />
            <div class="flex gap-2 items-center">
              <a href="tel:+917355039827"
                ><p
                  class="text-[#123177] py-3 text-[20px] rounded-3xl flex gap-2 items-center"
                >
                  <img
                    src="@/assets/program/phonecall.png"
                    alt="call-icon"
                    class="w-[30px] h-[30px]"
                  />
                  +91 7355039827
                </p></a
              >
              <!-- <a href="mailto:zeba@learningshala.in"><p
                class="text-[#123177] py-3 text-[20px] rounded-3xl flex gap-2 items-center"
              >
                <img
                  src="@/assets/program/envalope.png"
                  alt="call-icon"
                  class="w-[40px] h-[40px]"
                />
                zeba@learningshala.in
              </p></a> -->
            </div>
          </div>
          <div class="flex justify-center items-center md:hidden">
            <img
              src="@/assets/program/sharda-Online-Logo.png"
              alt=""
              srcset=""
              class=""
            />
          </div>
        </div>
      </div>
    </section>
    <!-- header end-->

    <section class="lg:border-b-2 lg:border-gray-400">
      <div class="bg-white lg:h-[560px] md:px-4 2xl:p-0">
        <!-- Desktop Banner -->
        <div
          class="w-full max-w-7xl lg:h-[560px] mx-auto hidden lg:block relative"
        >
          <div class="lg:flex lg:justify-between items-center h-full px-5">
            <!-- content -->
            <div class="flex flex-col lg:gap-6 xl:gap-8 w-[350px] xl:w-full">
              <div class="">
                <h1
                  class="lg:text-[26px] xl:text-[35px] text-[#17479e] text-[24px] leading-tight md:text-left font-bold"
                >
                  Online Degree Programs
                </h1>
              </div>
              <div
                class="text-left text-sm xl:text-lg bg-[#17479e] xl:w-max p-2 text-white"
              >
                <span class="pr-2 border-r-2">MBA</span
                ><span class="pr-2 pl-2 border-r-2">MCA</span
                ><span class="pr-2 pl-2 border-r-2">M.COM</span
                ><span class="pr-2 border-r-2 pl-2">BBA</span
                ><span class="pr-2 border-r-2 pl-2">BCA</span>
                <!-- <span class="pr-2 border-r-2 pl-2">B.COM</span
                  > -->
                <span class="pr-2 pl-2">BA (Hons.)</span>
              </div>
              <ul class="text-black flex flex-col gap-1 px-5 md:px-0">
                <li v-for="list in lists" :key="list">
                  <div class="flex gap-2 items-center">
                    <img :src="list.image" alt="" srcset="" class="w-5 h-5" />
                    <p class="font-bold text-lg">{{ list.content }}</p>
                  </div>
                </li>
              </ul>

              <h1 class="lg:text-xl xl:text-3xl text-black font-bold text-left">
                Admissions Open 2024
              </h1>
              <p class="font-semibold text-black text-md text-left">
                Batch Starts - July 2024
              </p>
            </div>

            <!-- desktop banner image -->
            <div class="hidden lg:block absolute bottom-0 left-[37%]">
              <img
                src="@/assets/program/learning-shala.webp"
                alt=""
                srcset=""
                class="xl:w-[385px] md:h-[250px] lg:w-[300px] lg:h-[455px] xl:h-[505px] object-contain"
              />
            </div>

            <!--Enquire form -->
            <div class="pt-5 lg:flex justify-center items-center">
              <div
                class="border py-4 bg-white shadow-lg w-[330px] 2xl:w-[350px]"
              >
                <LpEnquireForm />
              </div>
            </div>
          </div>
        </div>

        <!-- Mobile Banner -->
        <div class="w-full lg:hidden">
          <div class="flex flex-col gap-3 py-4 px-5">
            <!-- content -->
            <div class="flex flex-col gap-4">
              <h1 class="text-[25px] font-bold text-black">
                Online Degree Programs
              </h1>
              <div
                class="text-left text-[10px] bg-[#17479e] w-full flex justify-center p-2 text-white"
              >
                <span class="pr-2 border-r-2">MBA</span
                ><span class="pr-2 pl-2 border-r-2">MCA</span
                ><span class="pr-2 pl-2 border-r-2">M.COM</span
                ><span class="pr-2 border-r-2 pl-2">BBA</span
                ><span class="pr-2 border-r-2 pl-2">BCA</span>
                <!-- <span class="pr-2 border-r-2 pl-2">B.COM</span
              > -->
                <span class="pr-2 pl-2">BA (Hons.)</span>
              </div>
            </div>
            <!-- <div class="mt-3 flex justify-center bg-[#17479e] rounded-md">
              <ul class="text-white" v-for="key in degrees" :key="key">
                <p class="text-[12px] p-2">{{ key }}</p>
              </ul>
            </div> -->
            <!-- <div
              class="text-left text-[10px] bg-[#17479e] w-full flex justify-center p-2 text-white"
            >
              <span class="pr-2 border-r-2">MBA</span
              ><span class="pr-2 pl-2 border-r-2">MCA</span
              ><span class="pr-2 pl-2 border-r-2">M.COM</span
              ><span class="pr-2 border-r-2 pl-2">BBA</span
              ><span class="pr-2 border-r-2 pl-2">BCA</span
              ><span class="pr-2 border-r-2 pl-2">B.COM</span
              ><span class="pr-2 pl-2">BA(Hons.)</span>
            </div> -->

            <div>
              <img
                src="@/assets/program/learning-shala-mobile.webp"
                alt=""
                srcset=""
                class="w-[180px] h-full mx-auto"
              />
            </div>
            <h1 class="text-black font-bold">UGC Entitled</h1>
            <p class="text-black font-bold">0 Cost EMI Option</p>
          </div>
          <!--Enquire form -->
          <div class="w-full mt-5 py-4 bg-[#123177]">
            <div class="flex flex-col justify-center items-center py-2">
              <h1 class="text-lg text-white font-bold text-left">
                Admissions Open 2024
              </h1>
              <p class="text-sm text-left text-white">Batch Start: July 2024</p>
            </div>
            <div class="w-[320px] md:w-[500px] mx-auto">
              <div class="border py-4 bg-white shadow-lg">
                <LpEnquireForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import LpEnquireForm from "@/components/LpEnquireForm.vue";

export default {
  name: "BannerView",
  components: {
    LpEnquireForm,
  },
  data() {
    return {
      lists: [
        {
          id: 1,
          image: require("@/assets/program/play.png"),
          content: "UGC Entitled",
        },
        {
          id: 2,
          image: require("@/assets/program/play.png"),
          content: "0 Cost EMI Option",
        },
      ],

      degrees: ["MBA |", "MCA |", "M.COM |", "BBA |", "BCA |", "B.Com |", "BA"],
    };
  },
};
</script>

<style></style>
